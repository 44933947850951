var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-12",attrs:{"id":"contact_us"}},[_c('v-row',[_c('v-col',{staticClass:"pb-5 mb-4",staticStyle:{"text-align":"center","padding-bottom":"0px","max-height":"50px"},attrs:{"cols":"12"}},[_c('p',{staticClass:"my-h1-white",staticStyle:{"font-weight":"600","text-align":"center","color":"#024bae"}},[_vm._v(" Contact Us ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{style:(_vm.$vuetify.breakpoint.mdAndUp
                ? 'padding-left:100px !important;padding-right:100px !important'
                : ''),attrs:{"justify":"center"}},[_vm._l((_vm.contactItems),function(item,index){return _c('v-col',{directives:[{name:"motion",rawName:"v-motion"}],key:index,class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-11' : 'my-7',staticStyle:{"text-align":"center"},attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown
                  ? 12
                  : _vm.$vuetify.breakpoint.mdOnly
                  ? 6
                  : 3,"initial":{
                opacity: 0,
                x: _vm.$vuetify.breakpoint.smAndDown ? 0 : index < 2 ? -100 : 100,
                y: 50,
              },"visible":{
                opacity: 1,
                x: 0,
                y: 0,
                transition: {
                  delay: 100,
                  duration: _vm.getDuration(index),
                },
              }}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"my-contact-item my-1"},[_vm._v(" "+_vm._s(item.name)+" ")])]),(item.responsability)?_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"my-contact-item-small my-1"},[_vm._v(" "+_vm._s(item.responsability)+" ")])]):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"my-contact-item-small my-0"},[_vm._v(" Cell: "+_vm._s(item.cell)+" ")])]),(item.cell2)?_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"my-contact-item-small my-0"},[_vm._v(" Cell: "+_vm._s(item.cell2)+" ")])]):_vm._e(),(item.office)?_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"my-contact-item-small my-0"},[_vm._v(" Office: "+_vm._s(item.office)+" ")])]):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('a',{staticClass:"my-contact-item-small my-0",attrs:{"href":'mailto:' + item.email}},[_vm._v(_vm._s(item.email))])])],1)}),_c('v-col',{directives:[{name:"motion",rawName:"v-motion"}],class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-11' : 'my-5',staticStyle:{"text-align":"center"},attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown
                  ? 12
                  : _vm.$vuetify.breakpoint.mdOnly
                  ? 6
                  : 3,"initial":{
                opacity: 0,
                x: _vm.$vuetify.breakpoint.smAndDown ? 0 : 100,
                y: 50,
              },"visible":{
                opacity: 1,
                x: 0,
                y: 0,
                transition: {
                  delay: 100,
                  duration: _vm.getDuration(3),
                },
              }}},[_c('a',{staticStyle:{"text-decoration":"none !important"},attrs:{"target":"_blank","href":"https://goo.gl/maps/Y1oNgDY1V2K6SXhX9"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"my-contact-item pt-3 mb-1"},[_vm._v(" Island Film Cuba Office ")])]),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('address',{staticClass:"my-contact-item-small my-1",staticStyle:{"font-style":"normal !important","line-height":"35px"}},[_vm._v(" Calle 36 No.320 entre 3era y 5ta."),_c('br'),_vm._v(" Residencial Sevilla – Apto 5, Miramar,"),_c('br'),_vm._v(" La Habana – Cuba ")])])],1)])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }