var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticStyle:{"margin-top":"55px"},attrs:{"dark":"","padless":""}},[_c('v-card',{staticClass:"siteWhiteBackground lighten-1 white--text text-center",staticStyle:{"width":"100%"},attrs:{"flat":"","tile":""}},[_c('v-divider',{staticStyle:{"border-color":"#024bae"}}),_c('v-card-text',_vm._l((_vm.social),function(social,index){return _c('a',{key:index,attrs:{"href":social.link,"target":"_blank"}},[_c('v-btn',{directives:[{name:"motion",rawName:"v-motion"}],staticClass:"mx-4 hover-effect1",staticStyle:{"color":"#024bae !important"},attrs:{"icon":"","initial":{
          opacity: 0,
          x: index < 1 ? -50 : 50,
        },"visible":{
          opacity: 1,
          x: 0,
          transition: {
            delay: 100,
            duration: 400,
          },
        }}},[_c('v-icon',{staticClass:"mt-6",attrs:{"size":"54px"}},[_vm._v(" "+_vm._s(social.icon)+" ")])],1)],1)}),0),_c('v-card-text',{staticStyle:{"color":"#024bae"},attrs:{"transition":"scale-transition"}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("Island Film")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }