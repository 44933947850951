var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{directives:[{name:"motion",rawName:"v-motion"}],staticClass:"px-8",attrs:{"initial":{
   opacity: 0,
   x: 0,
   y: 80,
   },"visible":{
   opacity: 1,
   x: 0,
   y: 0,
   transition: {
    delay: 100,
    duration: 500,
   },
  }}},[_c('v-carousel',{attrs:{"hide-delimiters":"","cycle":"","height":"330"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.rows_number),function(image){return _c('v-carousel-item',{key:image,attrs:{"eager":""}},[_c('v-sheet',{attrs:{"height":"100%"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},_vm._l((_vm.cols_number),function(n){return _c('v-col',{key:n,attrs:{"cols":_vm.col_size}},[_c('v-img',{staticClass:"grey lighten-2 hover-effect2 hover-effect3",staticStyle:{"margin-left":"1px !important","margin-right":"1px !important","margin-top":"1px !important","margin-bottom":"1px !important","max-height":"330px","border-radius":"10px","box-shadow":"0px 3px 1px -2px rgba(0, 0, 0, 0.2),"},attrs:{"src":_vm.getSrc(n-1, image),"aspect-ratio":"1","gradient":"to bottom, rgba(33, 33, 33, 0.31), rgba(33, 33, 33, 0.62)"},on:{"click":function($event){_vm.selectMedia(_vm.getIndex(n, image))}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)}),1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }