<template>
<v-container>
    <v-row v-motion
      :initial="{
        opacity: 0,
        y: 150,
      }"
      :visible="{
        opacity: 1,
        y: 0,
        transition: {
          delay: 100,
          duration: 600,
        },
      }">
        <v-col align="center" cols="12"><h1 class="my-h1-blue">Welcome to the production world of Cuba</h1></v-col>
        <v-col align="center" cols="12"><p class="my-page-text">Take advantage of everything Cuba has to offer!
From the most diverse location spots to multi-ethnic cast, come and experience the hidden beauty of this magical island.
With it’s 27+ years of experience, Island Film will take care of all your production needs making sure your film, video and print visions are brought to life.
</p></v-col>
    </v-row>
</v-container>
</template>
<script>
  export default {
    name: 'HomeAboutUs',
    data: () => ({
    }),
  }
  </script>