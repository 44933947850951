var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('HeaderDefault'),_c('v-main',{attrs:{"id":"MainContent"}},[_c('v-container',[_c('v-row',{directives:[{name:"motion",rawName:"v-motion"}],attrs:{"initial":{
          opacity: 0,
          y: 150,
        },"visible":{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            duration: 600,
          },
        }}},[_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('h2',{staticClass:"my-h1-blue"},[_vm._v("Clients")])]),_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('p',{staticClass:"my-page-text"},[_vm._v(" Have a look at who has been working with us over the past 20 years: "),_c('br'),_c('br')])])],1),_c('v-row',{staticClass:"mb-6 px-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_vm.clients),function(client,index){return _c('v-col',{directives:[{name:"motion",rawName:"v-motion"}],key:index,staticClass:"py-1",attrs:{"initial":{
                opacity: 0,
                y: 25,
              },"visible":{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 100,
                  duration: 600,
                },
              },"cols":_vm.$vuetify.breakpoint.xsOnly
                  ? 6
                  : _vm.$vuetify.breakpoint.smOnly
                  ? 4
                  : _vm.$vuetify.breakpoint.mdOnly
                  ? 4
                  : 2}},[_c('label',{staticClass:"clientsText"},[_vm._v("•"+_vm._s(client))])])}),1)],1)],1)],1)],1),_c('div',{directives:[{name:"motion",rawName:"v-motion"}],attrs:{"initial":{
      opacity: 0,
      y: 150,
    },"visible":{
      opacity: 1,
      y: 0,
      transition: {
        delay: 100,
        duration: 600,
      },
    }}},[_c('HomeContactParallax'),_c('FooterDefault')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }