var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"hamburger-container"},[(_vm.menuOpen == false)?_c('v-img',{staticClass:"hover-effect1 resize-hamburger",class:!_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : '',staticStyle:{"z-index":"1000"},attrs:{"contain":"","src":"images/hamburger2.png","transition":"scale-transition"},on:{"click":function($event){_vm.menuOpen = !_vm.menuOpen}}}):_vm._e()],1),_c('video',{attrs:{"width":"100%","height":"auto","id":"background-video","playsinline":"","muted":"","autoplay":"","loop":"","onplay":_vm.handleFirstPlay(),"poster":"images/Slide10.jpg"},domProps:{"muted":true}},[_c('source',{attrs:{"src":"videos/1.mp4","type":"video/mp4"}})])]),_c('v-expand-transition',[_c('v-app-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.my_scrollY > 132),expression:"my_scrollY > 132"}],staticStyle:{"box-shadow":"inset 10px -10px 20px #00000029, 3px 3px 6px #0000001a","height":"74px"},attrs:{"app":"","color":"rgb(255,255,255,1)","dark":""}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[(_vm.my_scrollY > 132)?_c('v-img',{staticClass:"mr-2 py-1 resize-logo",staticStyle:{"margin-top":"12px !important"},attrs:{"alt":"Islandfilmcuba Logo","contain":"","src":"images/logo.png","transition":"scale-transition"}}):_vm._e()],1),_c('div',{staticStyle:{"position":"absolute","right":"20px","top":"8px"}},[_c('v-menu',{attrs:{"offset-y":"","left":"","content-class":_vm.my_scrollY > 132 ? 'my-menu' : 'my-menu2',"transition":"slide-x-transition","close-on-click":false,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"top":"5px"},style:(_vm.$vuetify.breakpoint.smAndDown
                  ? 'width: 25px !important'
                  : 'width: 45px !important'),attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[(_vm.my_scrollY > 132 || _vm.$vuetify.breakpoint.mdAndDown)?_c('v-img',_vm._g(_vm._b({staticClass:"hover-effect1 resize-hamburger",staticStyle:{"padding-top":"15px","z-index":"-1000"},attrs:{"contain":"","src":"images/hamburger.png","transition":"scale-transition"}},'v-img',attrs,false),on)):_vm._e()],1)]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('v-list',{staticStyle:{"height":"100vh","background-color":"rgb(33, 33, 33, 0.95)"}},_vm._l((_vm.menuItems),function(menuItem,i){return _c('v-list-item',{key:i,staticStyle:{"height":"65px"},style:([{ marginTop: i == 0 ? '135px' : '0px' }])},[_c('v-list-item-title',{staticClass:"my-menu-item hover-effect1"},[(menuItem.link.indexOf('#') == -1)?_c('router-link',{staticStyle:{"color":"#ffffff","text-decoration":"none !important"},attrs:{"to":menuItem.link},on:{"click":function($event){_vm.menuOpen = false}}},[_vm._v(_vm._s(menuItem.title))]):_c('a',{staticStyle:{"color":"#ffffff","text-decoration":"none !important"},attrs:{"href":menuItem.link},on:{"click":function($event){_vm.menuOpen = false}}},[_vm._v(_vm._s(menuItem.title))])],1)],1)}),1)],1)],1)])],1),(_vm.menuOpen)?_c('v-img',{staticClass:"px-1 py-1 hover-effect1",staticStyle:{"height":"auto !important","z-index":"11","position":"fixed !important","right":"0px"},style:([
      { top: _vm.my_scrollY > 132 ? '94px !important' : '10px !important' },
      {
        right: !_vm.$vuetify.breakpoint.smAndDown
          ? '22px !important'
          : '18px !important',
      },
      {
        width: !_vm.$vuetify.breakpoint.smAndDown
          ? '40px !important'
          : '30px !important',
      } ]),attrs:{"contain":"","src":"images/close.png","transition":"scale-transition"},on:{"click":function($event){_vm.menuOpen = false}}}):_vm._e(),_c('div',{staticStyle:{"width":"100%","position":"absolute","text-align":"center"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'top: 8px' : 'top: 5px')},[_c('v-img',{staticClass:"mr-2 py-1",staticStyle:{"width":"auto","z-index":"10"},style:(_vm.$vuetify.breakpoint.xsOnly ? 'height: 90px' : 'height: 128px'),attrs:{"alt":"Islandfilmcuba Logo","contain":"","src":"images/logo2.png","transition":"scale-transition"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }