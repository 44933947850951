<template>
  <v-app>
    <HeaderDefault />
    <v-main id="MainContent">
      <v-container>
        <v-row
          v-motion
          :initial="{
            opacity: 0,
            y: 150,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          }"
        >
          <v-col align="center" cols="12">
            <h2 class="my-h1-blue">Clients</h2></v-col
          >
          <v-col align="center" cols="12"
            ><p class="my-page-text">
             Have a look at who has been working with us over the past 20 years:
              <br /><br /></p
          ></v-col>
        </v-row>
        <v-row class="mb-6 px-6">
          <v-col cols="12">
            <v-row>
              <v-col
                v-motion
                :initial="{
                  opacity: 0,
                  y: 25,
                }"
                :visible="{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 100,
                    duration: 600,
                  },
                }"
                v-for="(client, index) in clients"
                :key="index"
                :cols="
                  $vuetify.breakpoint.xsOnly
                    ? 6
                    : $vuetify.breakpoint.smOnly
                    ? 4
                    : $vuetify.breakpoint.mdOnly
                    ? 4
                    : 2
                "
                class="py-1"
              >
                <label class="clientsText">•{{ client }}</label>
              </v-col>
            </v-row>
          </v-col>
          <!--- -->
          <!--<v-row
            v-motion
            :initial="{
              opacity: 0,
              y: 150,
            }"
            :visible="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                duration: 600,
              },
            }"
          >
            <v-col align="center" cols="12" class="my-12">
              <h1 class="my-h1-blue">Production Companies</h1></v-col
            >
          </v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                v-motion
                :initial="{
                  opacity: 0,
                  y: 15,
                }"
                :visible="{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 100,
                    duration: 600,
                  },
                }"
                v-for="(company, index) in companies"
                :key="index"
                :cols="
                  $vuetify.breakpoint.xsOnly
                    ? 12
                    : $vuetify.breakpoint.smOnly
                    ? 6
                    : $vuetify.breakpoint.mdOnly
                    ? 4
                    : index == companies.length - 1
                    ? 4
                    : 3
                "
              >
                <label class="clientsText">•{{ company }}</label>
              </v-col>
            </v-row>
          </v-col>
          <v-row
            v-motion
            :initial="{
              opacity: 0,
              y: 150,
            }"
            :visible="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                duration: 600,
              },
            }"
          >
            <v-col align="center" cols="12" class="my-12">
              <h1 class="my-h1-blue">Directors</h1></v-col
            >
          </v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                v-motion
                :initial="{
                  opacity: 0,
                  y: 15,
                }"
                :visible="{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 100,
                    duration: 600,
                  },
                }"
                v-for="(director, index) in directors"
                :key="index"
                :cols="
                  $vuetify.breakpoint.xsOnly
                    ? 6
                    : $vuetify.breakpoint.smOnly
                    ? 4
                    : $vuetify.breakpoint.mdOnly
                    ? 4
                    : 2
                "
              >
                <label class="clientsText">•{{ director }}</label>
              </v-col>
            </v-row>
          </v-col>
          <v-row
            v-motion
            :initial="{
              opacity: 0,
              y: 150,
            }"
            :visible="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                duration: 600,
              },
            }"
          >
            <v-col align="center" cols="12" class="my-12">
              <h1 class="my-h1-blue">Artists</h1></v-col
            >
          </v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                v-motion
                :initial="{
                  opacity: 0,
                  y: 15,
                }"
                :visible="{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 100,
                    duration: 600,
                  },
                }"
                v-for="(artist, index) in artists"
                :key="index"
                :cols="
                  $vuetify.breakpoint.xsOnly
                    ? 6
                    : $vuetify.breakpoint.smOnly
                    ? 4
                    : $vuetify.breakpoint.mdOnly
                    ? 4
                    : 3
                "
              >
                <label class="clientsText">•{{ artist }}</label>
              </v-col>
            </v-row>
          </v-col>-->
        </v-row>
      </v-container>
    </v-main>
    <div
      v-motion
      :initial="{
        opacity: 0,
        y: 150,
      }"
      :visible="{
        opacity: 1,
        y: 0,
        transition: {
          delay: 100,
          duration: 600,
        },
      }"
    >
      <HomeContactParallax />
      <FooterDefault />
    </div>
  </v-app>
</template>

<script>
import HeaderDefault from "./parts/HeaderDefault";
import FooterDefault from "./parts/FooterDefault";
import HomeContactParallax from "./parts/HomeContactParallax";

export default {
  name: "PermitsPage",

  components: {
    HeaderDefault,
    HomeContactParallax,
    FooterDefault,
  },

  data: () => ({
    clients: [
      "Axe",
      "Agos",
      "All Days",
      "Atol",
      "Apap",
      "Barilla – Pavesini",
      "Bounty",
      "Breil",
      "Bridgestone",
      "Bulmers",
      "Cadey – Bilboa",
      "Cadey – Guam",
      "CBS-London",
      "CBS-US",
      "Ceres",
      "CIAI",
      "Citroen",
      "Cocostar",
      "De Agostini",
      "Dietorelle",
      "Douwe Egberts",
      "D-Raizen",
      "Exoticos",
      "Elidor",
      "Elle",
      "Feh",
      "Ferrero – Nutella",
      "Fox",
      "Fonzies",
      "Freaky Fries",
      "FTI",
      "Gini",
      "Grana Padano",
      "Havana Club",
      "Illva Saronno",
      "ING Bank",
      "Italian Petrol",
      "Jop",
      "KFC",
      "La Vaque Qui Rit",
      "Libero",
      "Lipton Ice Tea",
      "Maru",
      "McDonald’s",
      "Meltin Pot",
      "Milupa",
      "Mokate",
      "Nestlé – Crunch",
      "Nescafé",
      "Nivea",
      "Nokia",
      "Nuova Brasilia",
      "Oasis",
      "Olimpia Splendid",
      "Original Marines",
      "Paglieri",
      "Pernord-Ricard",
      "Pfanni",
      "Parmalat – Grisbì",
      "Parmalat – Santal",
      "Posco",
      "Redd’s",
      "Rising Crust Pizza",
      "Ron Varadero",
      "Sammontana",
      "Samsung",
      "Sears",
      "Segafredo",
      "Selected",
      "Shyno",
      "Showtime – “House of Lies”",
      "Simmenthal",
      "Slavutich",
      "Sorgenia",
      "Sport Week Magazine",
      "Sunlight",
      "Tennents",
      "TIM",
      "Tropical Store",
      "Valtur",
      "Vapor Shot",
      "Virgin Wear",
      "Zuegg",
      "Vogue",
      "Marie Claire",
      "French Revue de Modes",
      "MAX",
      "L'Officiel",
      "D La Repubblica",
      "Sezane",
      "Air Wick",
      "C&A",
      "Places+Faces",
      "SONY",
      "BBC",
      "Riachuelo",
      "Margot",
      "T-Mobile",
      "HONDA",
      "Deichman",
      "LEICA",      
    ],
    companies: [
      "235 Film – Canada",
      "Akita Film – Italy",
      "Alta Marea – Italy",
      "Alto Verbano – Italy",
      "Avana – Italy",
      "Base CampProduction – Corea",
      "Basement – Italy",
      "Big Chief Films – UK",
      "Bistro Films – Czech Republic",
      "Blink Pictures – Canada",
      "Bongo Film – Germany",
      "Brothers and Sister – UK",
      "BRW – Italy",
      "Casta Diva Films – Italy",
      "Central – Italy",
      "Cheil – Korea",
      "Chocolat Productions – Italy",
      "Clap35 – France",
      "Done and Dusted – UK",
      "Duex- Italy",
      "Emanon Film – Germany",
      "E+P Commercial – Germany",
      "4 Film – Turkey",
      "Factory Film – UK",
      "The Family – Italy",
      "Film House Wien – Austria",
      "Film Master – Italy",
      "Film Master Clip – Italy",
      "Film World – Italy",
      "Flying – Italy",
      "Flynn – UK",
      "French TV – France",
      "Gang Film – France",
      "Germaine – UK",
      "Graffiti Film – Poland",
      "G-Spot – Holland",
      "Harold – Italy",
      "Hamster Publicite – France",
      "HIS London – UK",
      "HSI – USAIDP – UK",
      "Indiana Production – Italy",
      "IWC – Media",
      "JADigital – UK",
      "Marken Film – Germany",
      "Mallinson Television Production – UK",
      "Matador – US",
      "Milano Cinematografica – Italy",
      "Mercurio -Italy",
      "Mother Film Company – Italy",
      "Movie Magic – Italy",
      "NCN – Italy",
      "New Partners – Italy",
      "Ninety Nine – France",
      "Nobody – France",
      "Oil Factory – UK",
      "Paco Cinematografica – Italy",
      "Partizan – UK",
      "Partners Film – Canada",
      "Program 33 – France",
      "Pronto Production – Ucraina",
      "ProProduction – Poland",
      "Putumayo – USA",
      "Radioactive Film – Ukraine",
      "Reactor – Turkey",
      "Redorfe – Taiwan",
      "Revolver – Canada",
      "Rokus Film – Canada",
      "Sonny London – UK",
      "Sparks Production – Canada",
      "Spyder J. Film – Italy",
      "Sterntag – Germany",
      "Sub Division – Germany",
      "Vigilante – UK",
      "Clou Production Servicies – USACompal – Spain",
    ],
    directors: [
      "Gian Abrile",
      "Paolo Ameli",
      "Metin Arolat",
      "Tomasz Augustynek",
      "Ana Paola Avetti",
      "Henri Barges",
      "Giovanni Bedeschi",
      "Carles Lazaro Bernabeu",
      "Annie Bradley",
      "Matteo Bonifazio",
      "Bosi & Sironi",
      "Federico Brugia",
      "Ulf Buddensieck",
      "Stephanie Buttle",
      "Paolo Calcagni",
      "Fabio Capalbo",
      "Jean-Luois Carrasco",
      "Paolo Caspani",
      "Jodi Chan",
      "Tim & Jeff Cronenweth",
      "Alessandro D’Alatri",
      "Marc Andre Debuyrne",
      "Vincent De Bruss",
      "Kevin De Freitas",
      "Jaime De La Peña",
      "Laurence Dunmore",
      "Ron Eichhorn",
      "Danielle Ermolli",
      "Justin Francis",
      "Ken Fox",
      "Sven Geling",
      "Thierry Gautier",
      "Maki Gherzi",
      "Stuart Gosling",
      "Howard Greenhalgh",
      "Adrian Hayward",
      "Andreas Halberg",
      "Volker Hannwaker",
      "Sven Harding",
      "Andrew Hilton",
      "Ben Hume",
      "Serdar Isik",
      "Ulrik Jensen",
      "Noble Jones",
      "Larus Jonsson",
      "Carl Larssoni",
      "Yushitake Kawano",
      "Tierry Le Goues",
      "Mikael Lypinsk",
      "Luca Lucini",
      "Margaret",
      "Malandruccolo",
      "Luca Maroni",
      "Jim Manera",
      "Lisa Mann",
      "Jean Luc Martin",
      "Christian Merret Palmair",
      "Sam Miller",
      "Carl Damien Morisot",
      "Ivo Mostertman",
      "Vincent Mouran",
      "Neslon Navarro Navarro",
      "Fabrizio Notari",
      "Andy Novoselov",
      "Damien O’Donnell",
      "Robert Patrick",
      "Matteo Pellegrini",
      "Leone Pompucci",
      "Nathan Prakash",
      "RavindraJoel Pront",
      "Bartek Rainski",
      "Jen Renfroe",
      "Barnaby Roper",
      "Thomas Russel",
      "Daniel Ruzicka",
      "Edouard Salier",
      "Marcus Savage",
      "Marco Schillaci",
      "Carlo A.Sigon",
      "Leo Solanes",
      "Jin-Wook Song",
      "Adam Toht",
      "Lourens Van Rensburg",
      "Will Van Der Vlugt",
      "Markus Virpio",
      "Ludovicus Vanwold",
      "Martin Wedderburn",
      "Benjamin Weinstein",
      "Robin Willis",
      "Wiz",
      "Kim-Hong Wong",
      "Harald Zwart",
      "Scott Lyonç",
      "Nick Rutter",
      "Greg Northfield",
    ],
    artists: [
      "Athlete",
      "Amanda",
      "Paolo Belli",
      "B4-4",
      "BelleFire",
      "Billy Klippert",
      "Boggie Pimps",
      "Cameron",
      "Divine",
      "Crown Playaz",
      "Cubanito 2002",
      "Edoardo Bennato",
      "Emma Holland",
      "Femi Kuty",
      "Habana Delirio 1830",
      "In Essence",
      "Inner City",
      "EtreKardinal",
      "Florent Pagny",
      "Lynn Mias",
      "Jamelia",
      "J.C & J.A",
      "Jessica Robinson",
      "Jimmy Rankin",
      "Joee",
      "Jovanotti",
      "Karl Wolff",
      "Leahy",
      "Loona",
      "Macumba (Life’s a Carnival)",
      "Maksim",
      "Mania",
      "Mars Volta",
      "Micro Maureen",
      "Nicole",
      "Orishas",
      "Phats & Smal",
      "Remont Vody",
      "Ricardo Lembo",
      "Sandy LambShanw Desman",
      "Shy FX",
      "Simply Red",
      "Sin Nueces para el Amor",
      "Soul Decision",
      "Tiziano Ferro",
      "The Rolling Stones",
      "The Trews",
      "Tong Bully",
      "Wild",
      "Will Young",
      "Zutons",
    ],
  }),
  methods: {
    scroll(id) {
      //MainContent
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.scroll("MainContent");
    }, 250);
  },
  created() {
      this.clients.sort();
  },
};
</script>
