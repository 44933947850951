<template>
  <v-footer dark padless style="margin-top:55px;">
    <v-card
      flat
      tile
      style="width: 100%;"
      class="siteWhiteBackground lighten-1 white--text text-center"      
    >
    <v-divider style="border-color: #024bae"></v-divider>
      <v-card-text>
        <a 
            v-for="(social, index) in social"  
            :key="index"               
            :href="social.link" 
            target="_blank"           
            >
        <v-btn          
          class="mx-4 hover-effect1"
          icon
          v-motion
          :initial="{
            opacity: 0,
            x: index < 1 ? -50 : 50,
          }"
          :visible="{
            opacity: 1,
            x: 0,
            transition: {
              delay: 100,
              duration: 400,
            },
          }"
          style="color: #024bae !important"
        >
          <v-icon size="54px" class="mt-6">
            {{ social.icon }}
          </v-icon>
        </v-btn>
       </a>
      </v-card-text>

      <!--<v-divider style="border-color: #024bae"></v-divider>-->

      <!--<v-card-text class="white--text pt-10 pb-10">
        <v-row justify="center"
          ><v-col :cols="12">
            <v-row class="py-8">
              <v-col
                v-motion
                :initial="{
                  opacity: 0,
                }"
                :visible="{
                  opacity: 1,
                  transition: {
                    delay: 100,
                    duration: 700,
                  },
                }"
                :cols="$vuetify.breakpoint.mdAndDown ? 6 : ''"
                :style="
                  !$vuetify.breakpoint.mdAndDown
                    ? 'width:12.5% !important;text-align: center'
                    : 'text-align: center;'
                "
                v-for="(menuItem, i) in menuItems"
                v-bind:key="i"
              >
                <router-link class="footer-items" :to="menuItem.link">{{ menuItem.title }}</router-link>    
              </v-col>
            </v-row>
          </v-col>          
        </v-row>
      </v-card-text>
      <v-divider style="border-color: #024bae"></v-divider>-->
      <v-card-text
        style="color: #024bae"
        transition="scale-transition"
      >
        {{ new Date().getFullYear() }} — <strong>Island Film</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    social: [
      {icon: "mdi-facebook",link:"https://www.facebook.com/IslandFilmCuba/"},
      /*{icon: "mdi-instagram", link: ""}*/
    ],
    menuItems: [
      { title: "Homepage", link: "/" },
      /*{ title: "About Us", link: "/about" },*/
      /*{ title: "Locations", link: "/locations" },*/
      /*{ title: "Casting", link: "/casting" },*/
      { title: "Clients", link: "/clients" },
      { title: "Show reel", link: "/reel" },
      /*{ title: "Behind the Scenes", link: "/behind" },*/
      /*{ title: "Contact Us", link: "/contact" },*/
    ],
    images: [
      {
        src: "images/Slide1.jpg",
      },
      {
        src: "images/Slide2.jpg",
      },
      {
        src: "images/Slide3.jpg",
      },
      {
        src: "images/Slide4.jpg",
      },
      {
        src: "images/Slide3.jpg",
      },
      {
        src: "images/Slide4.jpg",
      },
      {
        src: "images/Slide2.jpg",
      },
      {
        src: "images/Slide4.jpg",
      },
    ],
  }),
};
</script>