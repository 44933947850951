import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueCompositionAPI from '@vue/composition-api'
import VueRouter from "vue-router"
import routes from './routes'
import storageHelper from 'storage-helper';

// Packages
import { MotionPlugin } from '@vueuse/motion'
Vue.use(VueCompositionAPI);
Vue.use(MotionPlugin);
Vue.use(VueRouter);
Vue.config.productionTip = false
const router = new VueRouter({
  routes:routes,
  mode: 'history'
  })
  router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
      if (!storageHelper.getItem('user-password') && 1==2) next('/protected')
      else next()
    } else next()
  })
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
