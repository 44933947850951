import Home from "./components/Home";
//import Locations from "./components/Locations";
//import AboutUs from "./components/AboutUs";
// import Casting from "./components/Casting";
import Permits from "./components/Permits";
import Reel from "./components/Reel";
// import Behind from "./components/Behind";
import Protected from "./components/Protected";
import NotFound from "./components/NotFound";

export default [
    {
        path: '/',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    /*{
        path: '/locations',
        component: Locations,
        meta: {
            requiresAuth: true
        }
    },   */ 
    /*{
        path: '/about',
        component: AboutUs,
        meta: {
            requiresAuth: true
        }
    },*/
    {
        path: '/clients',
        component: Permits,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reel',
        component: Reel,
        meta: {
            requiresAuth: true
        }
    },
    /*{
        path: '/behind',
        component: Behind,
        meta: {
            requiresAuth: true
        }
    },*/
    {
        path: '/protected',
        component: Protected,
    },
    {
        path: '*',
        name: 'Not Found',
        component: NotFound
    }
];