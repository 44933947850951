<template>
  <v-row class="px-0">
    <div style="width: 100%">
      <div class="hamburger-container">
        <v-img
          :class="!$vuetify.breakpoint.smAndDown ? 'mr-2' : ''"
          class="hover-effect1 resize-hamburger"
          contain
          @click="menuOpen = !menuOpen"
          v-if="menuOpen == false"
          src="images/hamburger2.png"
          style="z-index: 1000"
          transition="scale-transition"
        />
      </div>
      <video
        width="100%"
        height="auto"
        id="background-video"
        playsinline
        muted
        autoplay
        loop
        :onplay="handleFirstPlay()"
        poster="images/Slide10.jpg"
      >
        <source src="videos/1.mp4" type="video/mp4" />
      </video>
    </div>
    <v-expand-transition>
      <v-app-bar
        app
        color="rgb(255,255,255,1)"
        dark
        v-show="my_scrollY > 132"
        style="
          box-shadow: inset 10px -10px 20px #00000029, 3px 3px 6px #0000001a;
          height: 74px;
        "
      >
        <div class="d-flex align-center" style="width: 100%">
          <v-img
            alt="Islandfilmcuba Logo"
            v-if="my_scrollY > 132"
            class="mr-2 py-1 resize-logo"
            contain
            src="images/logo.png"
            style="margin-top: 12px !important"
            transition="scale-transition"
          />
        </div>
        <div style="position: absolute; right: 20px; top: 8px">
          <v-menu
            offset-y
            left
            v-model="menuOpen"
            :content-class="my_scrollY > 132 ? 'my-menu' : 'my-menu2'"
            transition="slide-x-transition"
            :close-on-click="false"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                style="top: 5px"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'width: 25px !important'
                    : 'width: 45px !important'
                "
              >
                <v-img
                  v-if="my_scrollY > 132 || $vuetify.breakpoint.mdAndDown"
                  contain
                  v-bind="attrs"
                  v-on="on"
                  class="hover-effect1 resize-hamburger"
                  style="padding-top: 15px; z-index: -1000"
                  src="images/hamburger.png"
                  transition="scale-transition"
                />
              </v-btn>
            </template>

            <v-list
              style="height: 100vh; background-color: rgb(33, 33, 33, 0.95)"
            >
              <v-list-item
                v-for="(menuItem, i) in menuItems"
                :key="i"
                style="height: 65px"
                :style="[{ marginTop: i == 0 ? '135px' : '0px' }]"
              >
                <v-list-item-title class="my-menu-item hover-effect1">
                  <router-link
                    @click="menuOpen = false"
                    v-if="menuItem.link.indexOf('#') == -1"
                    style="color: #ffffff; text-decoration: none !important"
                    :to="menuItem.link"
                    >{{ menuItem.title }}</router-link
                  >
                  <a
                    v-else
                    @click="menuOpen = false"
                    v-bind:href="menuItem.link"
                    style="color: #ffffff; text-decoration: none !important"
                    >{{ menuItem.title }}</a
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>
    </v-expand-transition>
    <v-img
      v-if="menuOpen"
      @click="menuOpen = false"
      class="px-1 py-1 hover-effect1"
      contain
      style="
        height: auto !important;
        z-index: 11;
        position: fixed !important;
        right: 0px;
      "
      :style="[
        { top: my_scrollY > 132 ? '94px !important' : '10px !important' },
        {
          right: !$vuetify.breakpoint.smAndDown
            ? '22px !important'
            : '18px !important',
        },
        {
          width: !$vuetify.breakpoint.smAndDown
            ? '40px !important'
            : '30px !important',
        },
      ]"
      src="images/close.png"
      transition="scale-transition"
    />
    <div
      style="width: 100%; position: absolute; text-align: center"
      :style="$vuetify.breakpoint.smAndDown ? 'top: 8px' : 'top: 5px'"
    >
      <v-img
        alt="Islandfilmcuba Logo"
        class="mr-2 py-1"
        contain
        src="images/logo2.png"
        style="width: auto; z-index: 10"
        :style="$vuetify.breakpoint.xsOnly ? 'height: 90px' : 'height: 128px'"
        transition="scale-transition"
      />
    </div>

    <!--<v-carousel
      cycle
      interval="8000"
      :height="
        !$vuetify.breakpoint.smAndDown
          ? '100vh'
          : '800px'
      "
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item v-for="(item, i) in items" :key="i">
        <v-sheet
          style="z-index: 10; background-size: cover"
          :style="{
            'background-image':
              'linear-gradient(to bottom, rgba(33, 33, 33, 0.62), rgba(33, 33, 33, 0.62)), ' +
              'url(' +
              item.src +
              ')',
          }"
          height="100%"
          tile
        >
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="12" align="center" justify="center"><div class="slideText">{{ item.text }}</div></v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>-->
  </v-row>
</template>

<script>
//import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition';
export default {
  name: "HeaderDefault",

  data: () => ({
    my_scrollY: 0,
    menuOpen: false,
    items: [
      /*{
        src: "images/Slide1.jpg",
        text: "Slide1 Title Text",
      },
      {
        src: "images/Slide2.jpg",
        text: "Slide2 Title Text"
      },
      {
        src: "images/Slide3.jpg",
        text: "Slide3 Title Text"
      },
      {
        src: "images/Slide4.jpg",
        text: "Slide4 Title Text"
      },*/
      {
        src: "images/Slide5.jpg",
        text: 'Discover the "unshot" beauty of this magical tropical island',
      },
      /*{
        src: "images/Slide6.jpg",
        text: "Highly experienced and multi-lingual staff"
      },
      {
        src: "images/Slide7.jpg",
        text: "All your production needs under one Roof"
      },*/
      {
        src: "images/Slide8.jpg",
        text: "Highly experienced and multi-lingual staff",
      },
      {
        src: "images/Slide9.jpg",
        text: "All your production needs under one Roof",
      },
      /*{
        src: "images/Slide10.jpg",
        text: "Slide10 Title Text"
      },*/
      {
        src: "images/Slide11.jpg",
        text: "Slide11 Title Text",
      },
      {
        src: "images/Slide12.jpg",
        text: "Slide12 Title Text",
      },
      {
        src: "images/Slide13.jpg",
        text: "Slide13 Title Text",
      },
    ],
    menuItems: [
      { title: "Homepage", link: "/" },
      /*{ title: "About Us", link: "/about" },*/
      { title: "Portfolio", link: "/#homePortfolio" },
      /*{ title: "Locations", link: "/locations" },*/
      /*{ title: "Casting", link: "/casting" },*/
      { title: "Clients", link: "/clients" },
      { title: "Show reel", link: "/reel" },
      /*{ title: "Behind the Scenes", link: "/behind" },*/
      { title: "Contact Us", link: "#contact_us" },
    ],
    videoPlayed: false,
  }),
  methods: {
    handleFirstPlay(){
      this.videoPlayed = true;
    },
    /* goTo(link){
      //var previous = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);            
      //window.location.href = previous + link;
    },*/
    handleScroll() {
      // Any code to be executed when the window is scrolled
      this.my_scrollY = window.scrollY;
      this.$root.$emit("scrollYChanged", {
        scrollY: window.scrollY,
      });
      //console.log( this.my_scrollY);
      /*this.isUserScrolling = (window.scrollY > 0);
      console.log('calling handleScroll', window.scrollY);*/
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
   /* setTimeout(() => {      
      var vid = document.getElementById("background-video");
      if(this.videoPlayed == false) {
        vid.muted = true;
        vid.play();
      }
      else vid.muted = false;
    },450);*/
  },

  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener("scroll", this.handleDebouncedScroll);
  },
};
</script>
<style scoped>
.v-carousel__controls {
  bottom: 20px;
}
#background-video {
  object-fit: cover;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 100% !important;
}
</style>
