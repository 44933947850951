<template>
  <div>
    <div class="container text-center" style="position:absolute;top:40%;">
      <h2>Please enter password to access this page.</h2>

      <div class="row">
        <div class="col-md-6 offset-md-3">
          <form v-on:submit.prevent="validateBeforeSubmit">
            <v-row align="center" justify="center">
              <v-text-field
                class="form-control password-field mt-6"
                type="password"
                name="password"
                v-model.trim="password"
                label="Password"
                outlined
              ></v-text-field>
            </v-row>
            <v-row align="center" justify="center">
              <span class="error help-block mt-3"></span>
            </v-row>
            <v-row align="center" justify="center">
              <div class="text-danger mt-3" v-if="error">
                <p>Incorrect password.</p>
              </div>
            </v-row>
            <v-row align="center" justify="center">
              <v-btn class="btn btn-primary mt-3" type="submit" color="green" style="color:#FFFFFF;width:200px;height:45px;">Submit</v-btn>
            </v-row>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storageHelper from "storage-helper";
export default {
  name: "ProtectedPage",
  data() {
    return {
      error: null,
      password: null,
    };
  },
  methods: {
    validateBeforeSubmit() {
      if (this.password === "IslandFilmSs927*FBe2di") {
        this.error = false;
        storageHelper.setItem("user-password", this.password);
        this.$router.push("/");
      } else {
        this.error = true;
      }
    },
  },
};
</script>
<style scoped>
.password-field {
  height: 45px;
  max-width:200px;
  border-radius: 6px;
}
</style>