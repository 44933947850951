<template>
  <v-app>
    <HeaderDefault />
    <v-main id="MainContent">
      <center>
        <v-row class="py-8" :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ''">
          <v-col
            v-motion
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visible="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                duration: 600,
              },
            }"
            align="center"
            cols="12"
            ><h1 class="my-h1-blue mb-4" style="color:#014aae;font-size:72px;">404<br>Page Not Found!</h1>
            <p>
             <router-link style="color:#014aae;font-size:32px;font-family: Bebas-Neue-Regular;" class="mt-12" to="/">Go home?</router-link>   
            </p>
          </v-col>
        </v-row>
      </center>
    </v-main>
    <HomeContactParallax />
    <FooterDefault />
  </v-app>
</template>

<script>
import HeaderDefault from "./parts/HeaderDefault";
import FooterDefault from "./parts/FooterDefault";
import HomeContactParallax from "./parts/HomeContactParallax";
export default {
  name: "NotFound",
  components: {
    HeaderDefault,
    HomeContactParallax,
    FooterDefault,
  },
  methods: {
    scroll(id) {
      //MainContent
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
   mounted() {
    setTimeout(() => {
      this.scroll("MainContent");
    }, 250);
  },
};
</script>
<style scoped>
center {
  margin: 15vw;
}
h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
