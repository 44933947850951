var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('HeaderDefault'),_c('v-main',[_c('HomeAboutUs'),_c('HomeCards')],1),_c('div',{directives:[{name:"motion",rawName:"v-motion"}],attrs:{"initial":{
      opacity: 0,
      y: 150,
    },"visible":{
      opacity: 1,
      y: 0,
      transition: {
        delay: 100,
        duration: 600,
      },
    }}},[_c('HomeContactParallax'),_c('FooterDefault')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }