<template>
  <v-app>
    <HeaderDefault />

    <v-main>
      <v-container>
        <v-row
          v-motion
          :initial="{
            opacity: 0,
            y: 150,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          }"
        >
          <v-col align="center" cols="12"
            ><h2 class="my-h1-blue">Reel</h2></v-col
          >
          <v-col align="center" cols="12"
            ><p class="my-page-text">
              Here you can have a preview of our past work and projects. Don’t
              hesitate to ask for more by contacting us at:
              <a target="_blank" href="mailto:islandfilm@islandfilmcuba.com"
                >islandfilm@islandfilmcuba.com</a
              ><br /><br />
              Scroll down for a sneak peak!<br /><br /></p
          ></v-col>
        </v-row>
        <v-row
          id="MainContent"
          align="center"
          justify="center"
          :class="$vuetify.breakpoint.mdAndUp ? 'px-12 py-12 mx-2' : 'py-3'"
          v-for="n in videos"
          :key="n"
          v-motion
          :initial="{
            opacity: 0,
            y: 150,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          }"
        >
          <v-col cols="12" align="center" justify="center">
            <video
              playsinline
              width="90%"
              height="auto"
              autoplay
              controls
              :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ''"
            >
              <source :src="'videos/' + n + '.mp4'" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </v-col>
          <v-col
            style="text-transform: uppercase; font-weight: 600 !important"
            cols="12"
            class="py-0"
            ><!--<label :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ''" class="clientsText">{{ titles[n - 1] }}</label>--><br
          /></v-col>
        </v-row>
      </v-container>
    </v-main>
    <div
      v-motion
      :initial="{
        opacity: 0,
        y: 150,
      }"
      :visible="{
        opacity: 1,
        y: 0,
        transition: {
          delay: 100,
          duration: 600,
        },
      }"
    >
      <HomeContactParallax />
      <FooterDefault />
    </div>
  </v-app>
</template>

<script>
import HeaderDefault from "./parts/HeaderDefault";
import FooterDefault from "./parts/FooterDefault";
import HomeContactParallax from "./parts/HomeContactParallax";

export default {
  name: "ReelPage",

  components: {
    HeaderDefault,
    HomeContactParallax,
    FooterDefault,
  },

  data: () => ({
    videos: 1,
    titles: ["Title Video 1"],
    //
  }),
  methods: {
    scroll(id) {
      //MainContent
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.scroll("MainContent");
    }, 250);
  },
};
</script>
