<template>
  <v-app>
    <HeaderDefault />

    <v-main>
      <HomeAboutUs />
      <HomeCards />
    </v-main>
    <div
      v-motion
      :initial="{
        opacity: 0,
        y: 150,
      }"
      :visible="{
        opacity: 1,
        y: 0,
        transition: {
          delay: 100,
          duration: 600,
        },
      }"
    >
      <HomeContactParallax />
      <FooterDefault />
    </div>
  </v-app>
</template>

<script>
import HeaderDefault from "./parts/HeaderDefault";
import FooterDefault from "./parts/FooterDefault";
import HomeContactParallax from "./parts/HomeContactParallax";
import HomeCards from "./parts/HomeCards";
import HomeAboutUs from "./parts/HomeAboutUs";
//import HomeGallery from "./parts/HomeGallery";

export default {
  name: "HomePage",

  components: {
    HeaderDefault,
    HomeContactParallax,
    HomeCards,
    FooterDefault,
    HomeAboutUs,
   // HomeGallery,
  },

  data: () => ({
    //
  }),
};
</script>
