var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('HeaderDefault'),_c('v-main',[_c('v-container',[_c('v-row',{directives:[{name:"motion",rawName:"v-motion"}],attrs:{"initial":{
          opacity: 0,
          y: 150,
        },"visible":{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            duration: 600,
          },
        }}},[_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('h2',{staticClass:"my-h1-blue"},[_vm._v("Reel")])]),_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('p',{staticClass:"my-page-text"},[_vm._v(" Here you can have a preview of our past work and projects. Don’t hesitate to ask for more by contacting us at: "),_c('a',{attrs:{"target":"_blank","href":"mailto:islandfilm@islandfilmcuba.com"}},[_vm._v("islandfilm@islandfilmcuba.com")]),_c('br'),_c('br'),_vm._v(" Scroll down for a sneak peak!"),_c('br'),_c('br')])])],1),_vm._l((_vm.videos),function(n){return _c('v-row',{directives:[{name:"motion",rawName:"v-motion"}],key:n,class:_vm.$vuetify.breakpoint.mdAndUp ? 'px-12 py-12 mx-2' : 'py-3',attrs:{"id":"MainContent","align":"center","justify":"center","initial":{
          opacity: 0,
          y: 150,
        },"visible":{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            duration: 600,
          },
        }}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('video',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'px-12' : '',attrs:{"playsinline":"","width":"90%","height":"auto","autoplay":"","controls":""}},[_c('source',{attrs:{"src":'videos/' + n + '.mp4',"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])]),_c('v-col',{staticClass:"py-0",staticStyle:{"text-transform":"uppercase","font-weight":"600 !important"},attrs:{"cols":"12"}},[_c('br')])],1)})],2)],1),_c('div',{directives:[{name:"motion",rawName:"v-motion"}],attrs:{"initial":{
      opacity: 0,
      y: 150,
    },"visible":{
      opacity: 1,
      y: 0,
      transition: {
        delay: 100,
        duration: 600,
      },
    }}},[_c('HomeContactParallax'),_c('FooterDefault')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }