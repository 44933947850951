<template>
  <v-row>
    <v-col class="px-8"
    v-motion
     :initial="{
     opacity: 0,
     x: 0,
     y: 80,
     }"
     :visible="{
     opacity: 1,
     x: 0,
     y: 0,
     transition: {
      delay: 100,
      duration: 500,
     },
    }"
    >
      <v-carousel v-model="model" hide-delimiters cycle height="330">
        <v-carousel-item v-for="image in rows_number" :key="image" eager>
          <v-sheet height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <v-col v-for="n in cols_number" :key="n" :cols="col_size">
                <v-img
                  :src="getSrc(n-1, image)"
                  aspect-ratio="1"
                  class="grey lighten-2 hover-effect2 hover-effect3"
                  style="
                    margin-left: 1px !important;
                    margin-right: 1px !important;
                    margin-top: 1px !important;
                    margin-bottom: 1px !important;
                    max-height: 330px;
                    border-radius: 10px;
                    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                  "
                  gradient="to bottom, rgba(33, 33, 33, 0.31), rgba(33, 33, 33, 0.62)"    
                  @click="selectMedia(getIndex(n, image))"              
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "HomeCarousel",
  props: {
    photo: {
      type: Array,
      default: () => [],
    },
    open: {},
  },
  data: () => ({
    model: 0,
    images: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    photography: [
      {
        src: "/images/Photography/1.jpg",
        type: "image",
        index: 1,
        array: "photography",
      },
    ],
  }),
  methods: {
    selectMedia(n){
      this.$root.$emit("selectMedia", {
        index: n-1,
      });
    },
    getSrc(col_number, row_number) {
      var cols_number = this.$vuetify.breakpoint.mdAndUp
        ? 4
        : this.$vuetify.breakpoint.smOnly
        ? 2
        : 1;
      var index = ((row_number * cols_number) - cols_number) + col_number;
      return 'images/Photo/' +  this.photo[index] + '_tn.jpg';
    },
    getIndex(col_number, row_number) {
      var cols_number = this.$vuetify.breakpoint.mdAndUp
        ? 4
        : this.$vuetify.breakpoint.smOnly
        ? 2
        : 1;
      return ((row_number * cols_number) - cols_number) + col_number + 1;      
    },
  },
  computed: {
    col_size() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 3
        : this.$vuetify.breakpoint.smOnly
        ? 6
        : 12;
    },
    cols_number() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 4
        : this.$vuetify.breakpoint.smOnly
        ? 2
        : 1;
    },
    rows_number() {
      var cols_number = this.$vuetify.breakpoint.mdAndUp
        ? 4
        : this.$vuetify.breakpoint.smOnly
        ? 2
        : 1;
      return Math.ceil(this.photo.length / cols_number)-1; // this.photo.length % cols_number > 0 ? (this.photo.length / cols_number) : this.photo.length / cols_number;
    },
  },
};
</script>
