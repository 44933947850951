var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('HeaderDefault'),_c('v-main',{attrs:{"id":"MainContent"}},[_c('center',[_c('v-row',{staticClass:"py-8",class:_vm.$vuetify.breakpoint.mdAndUp ? 'px-12' : ''},[_c('v-col',{directives:[{name:"motion",rawName:"v-motion"}],attrs:{"initial":{
            opacity: 0,
            y: 100,
          },"visible":{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          },"align":"center","cols":"12"}},[_c('h1',{staticClass:"my-h1-blue mb-4",staticStyle:{"color":"#014aae","font-size":"72px"}},[_vm._v("404"),_c('br'),_vm._v("Page Not Found!")]),_c('p',[_c('router-link',{staticClass:"mt-12",staticStyle:{"color":"#014aae","font-size":"32px","font-family":"Bebas-Neue-Regular"},attrs:{"to":"/"}},[_vm._v("Go home?")])],1)])],1)],1)],1),_c('HomeContactParallax'),_c('FooterDefault')],1)}
var staticRenderFns = []

export { render, staticRenderFns }