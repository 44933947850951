<template>
  <div>
    <v-row class="py-2">
      <v-overlay
        :value="overlay"
        :opacity="opacity"
        width="100%"
        height="100%"
        class="my-overlay"
      >
        <div style="width: 100%; max-with: 100%; height: 100% !important;max-height: 100% !important;">
          <v-img
            v-if="currentArray[selectedImage - 1].type == 'image'"
            contain
            style="
              max-width: 100%;
              height: auto;
              transition: all 300ms;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              transition: opacity 0.3s linear;
            "
            :style="'opacity:' + selectedImageOpacity + ';'"
            :src="currentArray[selectedImage - 1].src"
            transition="fade-transition"
          >
          </v-img>
          <video
            id="videoElement"
            v-else
            playsinline
            fullscreen
            autoplay
            width="90%"
            height="auto"
            controls
            :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ''"
            style="
              width: auto;
              height: auto;
              transition: all 300ms;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              transition: opacity 0.3s linear;
              max-height: 90% !important;
              max-width: 90% !important;
            "
            :style="'opacity:' + selectedImageOpacity + ';'"
            transition="fade-transition"
          >
            Your browser does not support the video tag.
          </video>
          <v-btn
            style="position: absolute; top: 50%; transform: translate(0%, -50%)"
            :style="$vuetify.breakpoint.smOnly ? 'right:60px' : 'right: 20px'"
            class="mx-2 hover-effect2"
            fab
            dark
            small
            color="rgb(255,255,255,.65)"
            @click="nextPicture()"
          >
            <v-icon dark color="#024bae"> mdi-chevron-right </v-icon>
          </v-btn>
          <v-btn
            style="position: absolute; top: 50%; transform: translate(0%, -50%)"
            :style="$vuetify.breakpoint.smOnly ? 'left:60px' : 'left: 20px'"
            class="mx-2 hover-effect2"
            fab
            dark
            small
            color="rgb(255,255,255,.65)"
            @click="previousPicture()"
          >
            <v-icon dark color="#024bae"> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn
            style="position: absolute"
            :style="
              $vuetify.breakpoint.smOnly
                ? 'top:60px; right: 60px'
                : 'top:20px; right: 20px'
            "
            class="mx-2 hover-effect2"
            fab
            dark
            small
            color="rgb(255,255,255,.65)"
            @click="overlay = false"
          >
            <v-icon dark color="#b20909"> mdi-close </v-icon>
          </v-btn>
        </div>
      </v-overlay>
    </v-row>
    <div
      id="homePortfolio"
      :class="
        $vuetify.breakpoint.mdAndUp
          ? 'px-12'
          : $vuetify.breakpoint.xsOnly
          ? 'mx-4'
          : 'mx-12'
      "
    >
      <v-row class="py-8" :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ''">
        <v-col
          v-motion
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          }"
          align="center"
          cols="12"
          ><h2 class="my-h1-blue">Portfolio</h2></v-col
        >
        <v-col
          v-motion
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          }"
          align="center"
          cols="12"
          ><h3 class="my-h3-blue">TV Series &amp; Movies</h3></v-col
        >
        <v-col
          v-motion
          :initial="{
            opacity: 0,
            x: getFromX(n),
            y: 30,
          }"
          :visible="{
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
              delay: 100,
              duration: getDuration(n),
            },
          }"
          :cols="
            $vuetify.breakpoint.smAndDown
              ? 12
              : $vuetify.breakpoint.mdOnly
              ? 6
              : 3
          "
          class="py-6 px-6"
          v-for="n in items.length"
          v-bind:key="n"
        >
          <v-card
            class="mx-auto hover-effect2"
            style="border-radius: 8px"
            max-width="500"
            v-if="items[n - 1].type !== 'special1'"
          >
            <v-row class="px-0">
              <v-col cols="6" class="pr-0">
                <v-img
                  class="white--text align-end"
                  height="400px"
                  :src="items[n - 1].thumb"
                >
                  <!--<v-card-title>{{ slides[n - 1] }}</v-card-title>-->
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#024bae"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="6" class="px-0">
                <v-card-subtitle class="movie-title ml-n2">
                  {{ items[n - 1].name }}</v-card-subtitle
                >
                <v-card-text class="text--primary">
                  <div class="my-movie-text ml-n2">
                    <b>Year:</b> {{ items[n - 1].year }}
                  </div>
                  <div class="my-movie-text ml-n2">
                    <b>Country:</b>
                    <v-img
                      style="width: 20px; height: auto; display: inline-block"
                      :src="items[n - 1].flag"
                    />
                    {{ items[n - 1].country }}
                  </div>
                  <div class="my-movie-text ml-n2">
                    <b>Production:</b> {{ items[n - 1].production }}
                  </div>
                  <div class="my-movie-text ml-n2">
                    <b>Director:</b> {{ items[n - 1].director }}
                  </div>
                </v-card-text>
                <v-card-actions
                  style="position: absolute; bottom: 0px; left: 50%"
                >
                  <v-btn
                    color="#024bae"
                    style="font-weight: 600"
                    text
                    class="ml-n2"
                    @click="selectMedia(items[n - 1].index, 'movies')"
                  >
                    Play Trailer
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            class="mx-auto hover-effect2"
            style="border-radius: 8px"
            max-width="500"
            v-else
          >
           <v-row class="px-0">
              <v-col cols="12">
                <div>
                  <div style="position:absolute;width:100%;height:100%;z-index:10;color:#FFFFFF;left:15px;top:45%;">
                    <div><p style="font-size:20px;">steve ●</p></div>
                    <div class="mt-n7 ml-6"><p style="font-size:30px;">MCCURRY</p></div>
                  </div>
                   <div style="position:absolute;width:100%;height:100%;z-index:10;color:#FFFFFF;left:0px;top:60%;">
                       <div><p style="font-size:16px;text-align:center">Learn the art of photographic storytelling from the iconic American photographer</p></div>
                  <div class="mt-n2"><p style="font-size:16px;text-align:center"> 25 lessons - 4hrs of content - Study Sheets</p></div>
                  <div class="mt-n4" style="font-size:16px;text-align:center">With subtitles in
                  <v-img
                      style="width: 20px; height: auto; display: inline-block"
                      src="images/GB.png"
                  />
                  <v-img
                      style="width: 20px; height: auto; display: inline-block; margin-left:6px;"
                      src="images/ES.png"
                  />
                  <v-img
                      style="width: 20px; height: auto; display: inline-block; margin-left:6px;"
                      src="images/FR.png"
                  />
                  <v-img
                      style="width: 20px; height: auto; display: inline-block; margin-left:6px;"
                      src="images/IT.png"
                  />
                  <v-img
                      style="width: 20px; height: auto; display: inline-block; margin-left:6px;"
                      src="images/DE.png"
                  />
                  <v-img
                      style="width: 20px; height: auto; display: inline-block; margin-left:6px;"
                      src="images/JP.png"
                  />
                  </div>
                  <div style="width:100%;text-align:center;">         
                    <a v-bind:href="'https://mastersof.photography/steve-mccurry/'">      
                    <v-btn
                    class="ma-2 my-special1-button"
                    :loading="loading4"
                    :disabled="loading4"
                    color="#c71015"
                    @click="loader = 'loading4'"
                    style="color:#FFFFFF;"
                    >
                    STEVE MCURRY COURSES >
                    </v-btn>
                   </a> 
                    </div>
                  </div>
                <v-img
                  class="white--text align-end"
                  height="400"
                  :src="items[n - 1].thumb"
                >
                <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#024bae"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                </div>
              </v-col>
           </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'px-12 py-8' : 'py-8'">
        <v-col
          v-motion
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          }"
          align="center"
          cols="12"
          ><h3 class="my-h3-blue pb-4">TVC</h3></v-col
        >
        <v-col
          :class="$vuetify.breakpoint.mdAndUp ? 'px-5' : ''"
          v-for="n in commercials.length"
          :key="n"
          :cols="
            $vuetify.breakpoint.xsOnly
              ? 12
              : $vuetify.breakpoint.lgAndUp
              ? 3
              : 6
          "
          style="height: 250px; border-radius: 10px"
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'margin-bottom:35px'
              : 'margin-bottom:25px'
          "
          v-motion
          :initial="{
            opacity: 0,
            x: getFromX(n),
            y: 30,
          }"
          :visible="{
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
              delay: 100,
              duration: getDuration(n),
            },
          }"
        >
          <div
            class="hover-effect2 hover-effect3"
            style="box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12);border-bottom-left-radius:10px;border-bottom-right-radius: 10px;border-top-left-radius: 10px;border-top-right-radius: 10px;"
          >
            <v-img
              :src="commercials[n - 1].thumb"
              :aspect-ratio="16 / 9"
              class="grey lighten-2"
              style="
                margin-left: 1px !important;
                margin-right: 1px !important;
                margin-top: 1px !important;
                margin-bottom: 1px !important;
                max-height: 220px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              "
              gradient="to bottom, rgba(33, 33, 33, 0.31), rgba(33, 33, 33, 0.62)"
              :title="commercials[n - 1].title"
              @click="selectMedia(commercials[n - 1].index, 'commercials')"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div>
              <p class="commercials-text py-2">
                {{ commercials[n - 1].title
                }}{{ commercials[n - 1].title !== "" ? " – " : ""
                }}<b style="color: #212121">{{ commercials[n - 1].client }}</b>
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'px-12 py-8' : 'py-8'">
        <v-col
          v-motion
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          }"
          align="center"
          cols="12"
          ><h3 class="my-h3-blue pb-4">Photography</h3></v-col
        >
        <HomeCarousel :photo="photo" />
        <!--<v-col
          class="px-5"
          v-for="n in photography.length"
          :key="n"
          :cols="
            $vuetify.breakpoint.xsOnly ? 12 : $vuetify.breakpoint.smOnly ? 6 : 3
          "
          style="max-height: 330px; margin-bottom: 40px"
          v-motion
          :initial="{
            opacity: 0,
            x: getFromX2(n),
            y: 30,
          }"
          :visible="{
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
              delay: 100,
              duration: getDuration2(n),
            },
          }"
        >
          <v-img
            :src="photography[n - 1].src"
            aspect-ratio="1"
            class="grey lighten-2 hover-effect2 hover-effect3"
            style="
              margin-left: 1px !important;
              margin-right: 1px !important;
              margin-top: 1px !important;
              margin-bottom: 1px !important;
              max-height: 330px;
              border-radius: 10px;
              box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            "
            gradient="to bottom, rgba(33, 33, 33, 0.31), rgba(33, 33, 33, 0.62)"
            :title="photography[n - 1].name"
            @click="selectMedia(photography[n - 1].index), 'photography'"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>-->
      </v-row>
      <v-row :class="$vuetify.breakpoint.mdAndUp ? 'px-12 py-8' : 'py-8'">
        <v-col
          v-motion
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visible="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              duration: 600,
            },
          }"
          align="center"
          cols="12"
          ><h3 class="my-h3-blue pb-4">Concerts &amp; Music Videos</h3></v-col
        >
        <v-col
          class="px-5"
          v-for="n in videos.length"
          :key="n"
          :cols="
            $vuetify.breakpoint.xsOnly ? 12 : $vuetify.breakpoint.smOnly ? 6 : 3
          "
          style="max-height: 330px; margin-bottom: 20px"
          v-motion
          :initial="{
            opacity: 0,
            x: getFromX2(n),
            y: 30,
          }"
          :visible="{
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
              delay: 100,
              duration: getDuration2(n),
            },
          }"
        >
          <!--<v-img
            :src="videos[n - 1].thumb"
            aspect-ratio="1"
            class="grey lighten-2 hover-effect2 hover-effect3"
            style="
              margin-left: 1px !important;
              margin-right: 1px !important;
              margin-top: 1px !important;
              margin-bottom: 1px !important;
              max-height: 330px;
              border-radius: 10px;
              box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            "
            gradient="to bottom, rgba(33, 33, 33, 0.31), rgba(33, 33, 33, 0.62)"
            :title="videos[n - 1].name"
            @click="selectMedia(videos[n - 1].index, 'videos')"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>-->
          <iframe
            width="100%"
            height="220"
            :src="videos[n - 1]"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
 <script>
import HomeCarousel from "./HomeCarousel";
export default {
  components: {
    HomeCarousel,
  },
  data: () => ({
    overlay: false,
    elementsDistance: 100,
    transitionDuration: 700,
    opacity: 0.9,
    selectedImage: 1,
    selectedImageOpacity: 1,
    videosRoute: "videos/commercials/",
    videosCount: 8,
    images: [],
    scrollY: 0,
    items: [
      {
        thumb: "images/movies10.jpg",
        src: "/videos/movies/10.mp4",
        name: "House of Lies",
        year: "2012-2016",
        production: "Refugee Productions (Broadcast by: Showtime)",
        distributor: "",
        country: "USA",
        director: "Matthew Carnahan",
        flag: "images/US.png",
        index: 1,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies5.jpg",
        src: "/videos/movies/5.mp4",
        name: "Viva",
        year: "2015",
        production: "Co-production Ireland-Cuba; Treasure Entertainment",
        distributor: "BTeam Pictures, ARP Sélection",
        country: "Ireland",
        director: "Paddy Breathnach",
        flag: "images/IE.png",
        index: 2,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies8.jpg",
        src: "/videos/movies/8.mp4",
        name: "Joanna Lumley's Hidden Caribbean: Havana to Haiti",
        year: "2020",
        production: "Burning Bright Productions, Independent Television",
        distributor: "",
        country: "UK",
        director: "Ewen Thomson",
        flag: "images/GB.png",
        index: 3,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies1.jpg",
        src: "/videos/movies/1.mp4",
        name: "Verliefd op Cuba",
        year: "2019",
        production: "Johan Nijenhuis & Co",
        distributor: "Entertainment One - eOne Films Benelux",
        country: "Netherlands",
        director: "Johan Nijenhuis",
        flag: "images/NL.png",
        index: 4,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies7.jpg",
        src: "/videos/movies/7.mp4",
        name: "Huey Morgan's Latin Music Adventure",
        year: "2020",
        production: "Factory Films",
        distributor: "",
        country: "UK",
        director: "Andy Dunn, Clare Tavernor",
        flag: "images/GB.png",
        index: 5,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies3.jpg",
        src: "/videos/movies/3.mp4",
        name: "Non è un paese per giovani",
        year: "2017",
        production:
          "Co-production Italy-Spain; Paco Cinematografica, Neo Art Producciones",
        distributor: "01 Distribution",
        country: "Italy",
        director: "Giovanni Veronesi",
        flag: "images/IT.png",
        index: 6,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies12.jpg",
        src: "/videos/movies/12.mp4",
        name: "Encounter",
        year: "2018-2019",
        production: "Bon Factory Worldwide",
        distributor: "",
        country: "South Korea",
        director: "Park Shin-woo",
        flag: "images/KR.png",
        index: 7,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies2.jpg",
        src: "/videos/movies/2.mp4",
        name: "Acorazado",
        year: "2010",
        production: "Jaibol Films",
        distributor: "Star Castle",
        country: "Mexico",
        director: "Álvaro Curiel",
        flag: "images/MX.png",
        index: 8,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies9.jpg",
        type: "special1",
      },
      {
        thumb: "images/movies4.jpg",
        src: "/videos/movies/4.mp4",
        name: "L'estate addosso",
        year: "2016",
        production:
          "Co-production Italy-United States; Indiana Production, RAI Cinema, Crosswind Advertising",
        distributor: "Strand",
        country: "Italy",
        director: "Gabriele Muccino",
        flag: "images/IT.png",
        index: 10,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies11.jpg",
        src: "/videos/movies/11.mp4",
        name: "Tiny World",
        year: "2020",
        production: "Plimsoll Productions",
        distributor: "",
        country: "UK",
        director: "Andrew Morwood",
        flag: "images/GB.png",
        index: 11,
        array: "movies",
        type: "video",
      },
      {
        thumb: "images/movies6.jpg",
        src: "/videos/movies/6.mp4",
        name: "Zhenshchiny protiv muzhchin",
        year: "2015",
        production: "Enjoy Movies",
        distributor: "",
        country: "Rusia",
        director: "Tair Mamedov",
        flag: "images/RU.png",
        index: 12,
        array: "movies",
        type: "video",
      },
    ],
    commercials: [
      {
        thumb: "/videos/commercials/13_thumb.jpg",
        src: "/videos/commercials/13.mp4",
        type: "video",
        index: 1,
        array: "commercials",
        title: "“Cuba UNICA”",
        client: "Disset",
      },
      {
        thumb: "/videos/commercials/10_thumb.jpg",
        src: "/videos/commercials/10.mp4",
        type: "video",
        index: 2,
        array: "commercials",
        title: "“Cuban Mode”",
        client: "Havana Club (Pernod Ricard)",
      },
      {
        thumb: "/videos/commercials/2_thumb.jpg",
        src: "/videos/commercials/2.mp4",
        type: "video",
        index: 3,
        array: "commercials",
        title: "“Summer”",
        client: "C&A",
      },
      {
        thumb: "/videos/commercials/3_thumb.jpg",
        src: "/videos/commercials/3.mp4",
        type: "video",
        index: 4,
        array: "commercials",
        title: "“Doutzen’s Summer Stories”",
        client: "Hunkemuller",
      },
      {
        thumb: "/videos/commercials/4_thumb.jpg",
        src: "/videos/commercials/4.mp4",
        type: "video",
        index: 5,
        array: "commercials",
        title: "“Colores de Cuba”",
        client: "Riachuelo",
      },
      {
        thumb: "/videos/commercials/5_thumb.jpg",
        src: "/videos/commercials/5.mp4",
        type: "video",
        index: 6,
        array: "commercials",
        title: "“Skepta”",
        client: "Havana Club (Pernod Ricard)",
      },
      {
        thumb: "/videos/commercials/6_thumb.jpg",
        src: "/videos/commercials/6.mp4",
        type: "video",
        index: 7,
        title: "",
        client: "Calzedonia",
      },
      {
        thumb: "/videos/commercials/7_thumb.jpg",
        src: "/videos/commercials/7.mp4",
        type: "video",
        index: 8,
        array: "commercials",
        title: "“Life’s a beach”",
        client: "Airwick",
      },
      {
        thumb: "/videos/commercials/8_thumb.jpg",
        src: "/videos/commercials/8.mp4",
        type: "video",
        index: 9,
        array: "commercials",
        title: "“Taxi”",
        client: "Margot",
      },
      {
        thumb: "/videos/commercials/9_thumb.jpg",
        src: "/videos/commercials/9.mp4",
        type: "video",
        index: 10,
        array: "commercials",
        title: "Generation Advanced",
        client: "Chronotech",
      },
      {
        thumb: "/videos/commercials/11_thumb.jpg",
        src: "/videos/commercials/11.mp4",
        type: "video",
        index: 11,
        array: "commercials",
        title: "“Kebab”",
        client: "Norway Lotto",
      },
      {
        thumb: "/videos/commercials/12_thumb.jpg",
        src: "/videos/commercials/12.mp4",
        type: "video",
        index: 12,
        array: "commercials",
        title: "“Ci vorrebbe un’amica”",
        client: "Amica chips",
      },
      {
        thumb: "/videos/commercials/1_thumb.jpg",
        src: "/videos/commercials/1.mp4",
        type: "video",
        index: 13,
        array: "commercials",
        title: "“Experience Colour in the Dark”",
        client: "Havana Club (Pernod Ricard)",
      },
    ],
    videos: [
      "https://www.youtube.com/embed/gseajEnDbm0",
      "https://www.youtube.com/embed/hE-FYOt0jmo",
      "https://www.youtube.com/embed/1qag-o1kfQY",
      "https://www.youtube.com/embed/pMv5vuSHMsU",
      "https://www.youtube.com/embed/bag0VtzK_9U",
      "https://www.youtube.com/embed/2ENI7oq6lCw",
      "https://www.youtube.com/embed/jp1yx25aHyM",
      "https://www.youtube.com/embed/FKc1sk3Fnb0",
      "https://www.youtube.com/embed/wpuZOWYobLI",
      //"https://www.youtube.com/embed/GgF4bTCYabU",
    ],
    photo: [
      "1",
      "10",
      "11",
      "12",
      "14",
      "15",
      "15a",
      "16",
      "17",
      "17a",
      "19a11",
      "19a12",
      "19a25",
      "19a28",
      "19a8",
      "19a9",
      "2",
      "20",
      "21",
      "23",
      "25",
      "26",
      "27",
      "28",
      "28a",
      "29",
      "3",
      "32",
      "33a",
      "33b",
      "33c",
      "33d",
      "33g",
      "33h",
      "34b",
      "34c",
      "34e",
      "35",
      "36",
      "38",
      "38a",
      "38a1",
      "39a Popular_04",
      "39b Popular_05",
      "39e Popular_10",
      "39g Popular_21",
      "39k Popular_35",
      "39l Popular_44",
      "39m Popular_47",
      "4",
      "40",
      "41",
      "42",
      "44",
      "45",
      "48",
      "4a5",
      "4a6",
      "5",
      "6",
      "7",
      "7a",
      "8",
      "8a",
      "8b",
      "8c",
      "8d",
      "9",
      "9a",
      "9b",
      "9c Boxing ballerinas 1",
      "9d-Boxing-ballerinas-2",
      "9f Boxing ballerinas 4",
      "9g Boxing ballerinas 5",
      "9h Boxing ballerinas 6",
    ],
    photography: [],
    currentArray: [],
    menuItems: [
      { title: "Homepage", link: "/" },
      { title: "About Us", link: "/about" },
      { title: "Locations", link: "/locations" },
      { title: "Casting", link: "/casting" },
      { title: "Permits & Assistance", link: "/permits" },
      { title: "Equipment", link: "/equipment" },
      { title: "Show reel", link: "/reel" },
      { title: "Behind the Scenes", link: "/behind" },
      { title: "Contact Us", link: "/contact" },
    ],
    slides: [
      "Slide1",
      "Slide2",
      "Slide3",
      "Slide4",
      "Slide5",
      "Slide6",
      "Slide7",
      "Slide8",
    ],
  }),
  created() {
    for (let i = 0; i < this.photo.length; i++) {
      this.photography.push({
        src: "/images/Photo/" + this.photo[i] + '.jpg',
        type: "image",
        index: i,
        array: "photography",
      });
    }
    /*if(this.$vuetify.breakpoint.xsOnly)
     this.videos.push('https://www.youtube.com/embed/wpuZOWYobLI');*/
    this.$root.$on("scrollYChanged", (data) => {
      this.scrollY = data.scrollY;
    });
    this.$root.$on("selectMedia", (data) => {
      this.selectMedia(data.index, "photo");
    });
    this.currentArray = [...this.photography];
  },
  methods: {
    nextPicture() {
      this.selectedImageOpacity = 0;
      setTimeout(() => {
        this.selectedImage =
          this.selectedImage < this.currentArray.length
            ? this.selectedImage + 1
            : 1;
        this.selectedImageOpacity = 1;
        if (this.currentArray[this.selectedImage - 1].type == "video") {
          setTimeout(() => {
            var v = document.getElementById("videoElement");
            v.setAttribute(
              "src",
              this.currentArray[this.selectedImage - 1].src
            );
          }, 10);
        }
      }, 250);
    },
    previousPicture() {
      this.selectedImageOpacity = 0;
      setTimeout(() => {
        this.selectedImage =
          this.selectedImage > 1
            ? this.selectedImage - 1
            : this.currentArray.length;
        this.selectedImageOpacity = 1;
        if (this.currentArray[this.selectedImage - 1].type == "video") {
          setTimeout(() => {
            var v = document.getElementById("videoElement");
            v.setAttribute(
              "src",
              this.currentArray[this.selectedImage - 1].src
            );
          }, 10);
        }
      }, 250);
    },
    selectMedia(n, array) {
      this.selectedImage = n;
      this.overlay = true;
      this.currentArray =
        array == "videos"
          ? [...this.videos]
          : array == "movies"
          ? [...this.items]
          : array == "commercials"
          ? [...this.commercials]
          : [...this.photography];
      console.log(this.currentArray[n - 1].src, "Selected");
      if (this.currentArray[n - 1].type == "video") {
        setTimeout(() => {
          var v = document.getElementById("videoElement");
          v.setAttribute("src", this.currentArray[n - 1].src);
          if (v.requestFullscreen) v.requestFullscreen();
          else if (v.webkitRequestFullscreen) v.webkitRequestFullscreen();
          else if (v.msRequestFullScreen) v.msRequestFullScreen();
        }, 250);
      }
    },
    getFromX(n) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 0;
      } else {
        if (n == 1 || n == 2 || n == 5 || n == 6 || n == 9 || n == 10)
          return -1 * this.elementsDistance;
        else return this.elementsDistance;
      }
    },
    getFromX2(n) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 0;
      } else {
        if (n == 1 || n == 2 || n == 5 || n == 6)
          return -1 * this.elementsDistance;
        else return this.elementsDistance;
      }
    },
    getDuration(n) {
      if (n == 2 || n == 3 || n == 6 || n == 7 || n == 10 || n == 11)
        return this.transitionDuration / 2;
      else return this.transitionDuration;
    },
    getDuration2(n) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (n == 2 || n == 3 || n == 6 || n == 7)
          return this.transitionDuration / 2;
        else return this.transitionDuration;
      } else {
        return this.transitionDuration;
      }
    },
  },
};
</script>
   
