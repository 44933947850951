<template>
  <div class="mt-12" id="contact_us">
    <!--  <v-parallax
        dark
        src="images/parallax.jpg"
        style="width: 100%"
        :style="$vuetify.breakpoint.smAndDown ? 'height:850px' : 'height:500px'"
      >-->
        <v-row>
          <v-col
            cols="12"
            style="text-align: center; padding-bottom: 0px; max-height: 50px"
            class="pb-5 mb-4"
          >
            <p
              class="my-h1-white"
              style="font-weight: 600; text-align: center;color:#024bae;"
            >
              Contact Us
            </p>
          </v-col>
          <v-col cols="12">
            <v-row
              justify="center"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'padding-left:100px !important;padding-right:100px !important'
                  : ''
              "
            >
              <v-col
                :class="$vuetify.breakpoint.smAndDown ? 'mb-11' : 'my-7'"
                style="text-align: center"
                :cols="
                  $vuetify.breakpoint.smAndDown
                    ? 12
                    : $vuetify.breakpoint.mdOnly
                    ? 6
                    : 3
                "
                v-for="(item, index) in contactItems"
                v-bind:key="index"
                v-motion
                :initial="{
                  opacity: 0,
                  x: $vuetify.breakpoint.smAndDown ? 0 : index < 2 ? -100 : 100,
                  y: 50,
                }"
                :visible="{
                  opacity: 1,
                  x: 0,
                  y: 0,
                  transition: {
                    delay: 100,
                    duration: getDuration(index),
                  },
                }"
              >
                <v-row justify="center"
                  ><p class="my-contact-item my-1">
                    {{ item.name }}
                  </p></v-row
                >
                <v-row v-if="item.responsability" justify="center"
                  ><p class="my-contact-item-small my-1">
                    {{ item.responsability }}
                  </p></v-row
                >
                <v-row justify="center"
                  ><p class="my-contact-item-small my-0">
                    Cell: {{ item.cell }}
                  </p></v-row
                >
                 <v-row v-if="item.cell2" justify="center"
                  ><p class="my-contact-item-small my-0">
                    Cell: {{ item.cell2 }}
                  </p></v-row
                >
                <v-row v-if="item.office" justify="center"
                  ><p class="my-contact-item-small my-0">
                    Office: {{ item.office }}
                  </p></v-row
                >
                <v-row justify="center"
                  ><a
                    class="my-contact-item-small my-0"
                    v-bind:href="'mailto:' + item.email"
                    >{{ item.email }}</a
                  ></v-row
                >
               <!-- <v-row v-if="item.skype" justify="center"
                  ><p class="my-contact-item-small my-0">
                    Skype: {{ item.skype }}
                  </p></v-row
                >-->
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.smAndDown ? 'mb-11' : 'my-5'"
                style="text-align: center"
                :cols="
                  $vuetify.breakpoint.smAndDown
                    ? 12
                    : $vuetify.breakpoint.mdOnly
                    ? 6
                    : 3
                "
                v-motion
                :initial="{
                  opacity: 0,
                  x: $vuetify.breakpoint.smAndDown ? 0 : 100,
                  y: 50,
                }"
                :visible="{
                  opacity: 1,
                  x: 0,
                  y: 0,
                  transition: {
                    delay: 100,
                    duration: getDuration(3),
                  },
                }"
              >
              <a style="text-decoration: none !important;" target="_blank" href="https://goo.gl/maps/Y1oNgDY1V2K6SXhX9">
                <v-row justify="center"
                  ><p class="my-contact-item pt-3 mb-1">
                    Island Film Cuba Office
                  </p></v-row
                >
                <v-row class="mt-2" justify="center"
                  ><address style="font-style: normal !important;line-height:35px;" class="my-contact-item-small my-1">
                    Calle 36 No.320 entre 3era y 5ta.<br>
                    Residencial Sevilla – Apto 5, Miramar,<br> La Habana – Cuba
                  </address></v-row
                >
                <!--<v-row justify="center"
                  ><p class="my-contact-item-small my-1">
                    Residencial Sevilla – Apto 5, Miramar, La Habana – Cuba
                  </p></v-row>-->
              </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      <!--</v-parallax>-->
  </div>
</template>
<script>
export default {
  name: "HomeContactParallax",
  data: () => ({
    transitionDuration: 700,
    contactItems: [
      {
        name: "Renzo Trivellini",
        cell: "+5352631437",
        email: "renzo@islandfilmcuba.com",
        skype: "renzotrive",
      },
      {
        name: "Nelson Navarro Navarro",
        cell: "+34689038202",
        cell2: "+5352631639",
        email: "nelson@islandfilmcuba.com",
        skype: "navarronavarro",
      },
      {
        name: "Judd Allison",
        responsability: "Project Development USA",
        cell: "+1 305 798-4070",
        office: "+1 305 759-9954",
        email: "Judd@juddallison.com",
      },
    ],
    //
  }),
  methods: {
    getDuration(n) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (n == 1 || n == 2) return this.transitionDuration / 2;
        else return this.transitionDuration;
      } else {
        return this.transitionDuration;
      }
    },
  },
};
</script>