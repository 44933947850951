var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{directives:[{name:"motion",rawName:"v-motion"}],attrs:{"initial":{
        opacity: 0,
        y: 150,
      },"visible":{
        opacity: 1,
        y: 0,
        transition: {
          delay: 100,
          duration: 600,
        },
      }}},[_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('h1',{staticClass:"my-h1-blue"},[_vm._v("Welcome to the production world of Cuba")])]),_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('p',{staticClass:"my-page-text"},[_vm._v("Take advantage of everything Cuba has to offer! From the most diverse location spots to multi-ethnic cast, come and experience the hidden beauty of this magical island. With it’s 27+ years of experience, Island Film will take care of all your production needs making sure your film, video and print visions are brought to life. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }